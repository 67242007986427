export const countryMap: Record<string, Array<string>> = {
    "USAmazon": [
        "US",
        "CA",
        "MX",
        "BR"
    ],
    "EUAmazon": [
        "GB",
        "DE",
        "FR",
        "IT",
        "ES",
        "IN",
        "AE",
        "TR",
        "SA",
        "NL",
        "SE",
        "PL",
        "EG",
        "BE",
    ],
    "JPAmazon": [
        "JP",
        "AU",
        "SG"
    ]
};

export enum PipelineStageEnum {
    BetaNA = 'Beta-NA',
    BetaEU = 'Beta-EU',
    BetaFE = 'Beta-FE',
    GammaNA = 'Gamma-NA',
    GammaEU = 'Gamma-EU',
    GammaFE = 'Gamma-FE',
    ProdNA = 'Prod-NA',
    ProdEU = 'Prod-EU',
    ProdFE = 'Prod-FE'
}

export const pipelineStage = "Beta-NA";